import React, { useEffect, useState } from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import api from '../../../../services/api';
import { dateLanguage, dateSimple, simpleDateUS } from '../../../../utils/date';
import { addHours } from 'date-fns';
import { Form } from '@unform/web';
import DatePicker from '../../core/components/Forms/DatePicker';
import downloadit, { downloaditPost } from '../../../../utils/download';
import { apiUrl } from '../../../../config';
import { useToast } from '../../../../hooks/Toast';
import { date } from 'yup';
import Loader from '../../core/components/Loader';
import { FaChartBar, FaChartPie, FaChevronCircleDown, FaChevronCircleUp, FaFileCsv, FaFileExcel, FaInfo, FaSpinner } from 'react-icons/fa';
import ChartBar from '../../core/components/Charts/Bar';
import PieChart from '../../core/components/Charts/Pie';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useAuth } from '../../../../hooks/Auth';


interface IGroup {
  search: Array<Record<string, any>>;
  data: Array<Record<string, any>>;
  where?: Record<string, any>;
  prefix?: string;
}


const getGroup = ({ data, search, where = {}, prefix = '' }: IGroup) => {

  const keys = Object.keys(where);


  let newData = data;
  /* Make a filter on data */
  keys.map(key => {

    newData = newData.filter(d => d[key] === where[key]);
  })

  const report: Array<IResume> = [];

  /* Searchable without ignored fields */
  search.map(s => {

    report.push({
      title: `${prefix}${s.label}`,
      column: s.ref, /* column name */
      data: []
    })


  })

  newData.map(filterDataLine => {


    report.map((item, index) => {

      const columnName = filterDataLine[item.column] ? filterDataLine[item.column].trim().toUpperCase() : 'Não informado';

      const indexSubItem = report[index].data.findIndex(elem => elem?.title === columnName);



      if (indexSubItem >= 0) {
        report[index].data[indexSubItem].count = report[index].data[indexSubItem].count + 1;
        report[index].data[indexSubItem].value = report[index].data[indexSubItem]?.value ? (report[index]?.data[indexSubItem]?.value || 0) + 1 : 1;
      }
      else {
        report[index].data.push({ column: item?.column, id: columnName, title: columnName, label: columnName, value: 1, count: 1 })
      }



    });


  })





  return report;

}


interface IDetails {
  title: string;
  column?: string;
  id?: string;
  count: number;
  label?: string;
  value?: number;
}


interface IResume {
  data: Array<IDetails>;
  title: string;
  column: string;
  setCallback?: Function;
  active?: boolean;
}

interface IResumeList {
  data: Array<IResume>
}

const ReadResumeList: React.FC<IResumeList> = ({ data }) => {



  const [currentResume, setCurrentResume] = useState('');



  return <>
    {data?.length > 0 ? data.map(resume => <ReadResume column={resume.column} title={resume.title} data={resume.data} active={resume.title === currentResume ? true : false} setCallback={(value) => setCurrentResume(value)} />) : <></>}
  </>

}

const ReadResume: React.FC<IResume> = ({ title = '', data, active = false, setCallback = (value) => { } }) => {

  const [type, setType] = useState('details');

  const convertToArrayAndExport = ({ data, type = 'xlsx', title }) => {
    data.sort((a, b) => a?.count < b?.count ? 1 : a?.count > b?.count ? -1 : 0);
    const content = [[title, 'Quantidade']];

    data.map(item => {
      content.push([item.title, item.count || 0])
    })



    return downloaditPost({ url: `${apiUrl}/converter/${type}`, data: content, type });

  }

  return <>

    <div className='list-template-dark'>
      <div className='list-template-date'>{title}</div>
      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <div className='list-template-quantity' style={{ width: '70px' }}>{data.length || '0'}</div>


        <div className='list-template-quantity' style={{ cursor: 'pointer' }}>
          <FaInfo onClick={() => { setCallback(title); setType('details') }} size={20} />
          <FaFileExcel size={20} onClick={() => convertToArrayAndExport({ title, data, type: 'xlsx' })} />
          <FaFileCsv size={20} onClick={() => convertToArrayAndExport({ title, data, type: 'csv' })} />
          {/*<FaChartBar onClick={() =>   { setType('chartBar'); setCallback(title)}} size={20}/> */}
          <FaChartPie onClick={() => { setType('chartPie'); setCallback(title) }} size={20} />{active ? <FaChevronCircleUp onClick={() => setCallback(title)} size={20} /> : <FaChevronCircleDown onClick={() => setCallback(title)} size={20} />}</div>
      </div>

    </div>
    <div style={{ display: active ? 'flex' : 'none', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <ReadDetails type={type} data={data} />

    </div>

  </>



}



interface IPropsReadDetails {
  data: Array<IDetails>;
  type: string;
}


const ReadDetails: React.FC<IPropsReadDetails> = ({ data, type = 'details' }) => {

  /* type can be "Details" or "Chart" */


  const [contentList, setContentList] = useState<Array<IDetails>>([]);
  const [content, setContent] = useState<Array<JSX.Element>>([]);

  const getContent = {
    details: (dataDetails: Array<IDetails>) => {
      return <table style={{ width: '100%' }}>
        {dataDetails.map((d, index) => {

          return <tr key={`${d.title}-${d.count}-${index}`} className='list-template'>
            <td className='list-template-date'>{d.title}</td>
            <td className='list-template-quantity'>{d.count || '0'}</td>
          </tr>

        })}
      </table>;




    },
    chartBar: (dataDetails: Array<IDetails>) => {
      return <div style={{ width: '100%', overflowX: 'scroll' }}>  <div style={{ width: '100%', minWidth: `${dataDetails.length * 100}px`, height: '50vh' }}><ChartBar indexBy="title" headers={['count']} data={dataDetails} /></div></div>

    },
    chartPie: (dataDetails: Array<IDetails>) => {
      return <div style={{ width: '100%', overflowX: 'scroll' }}>  <div style={{ width: '100%', height: '50vh', padding: '20px 0px', overflowY: 'auto' }}><PieChart indexBy="title" headers={['count']} data={dataDetails} /></div></div>

    }

  }


  useEffect(() => {

    const newList = [...data];

    newList.sort((a, b) => {
      if (a?.count < b?.count) { return 1; }
      else if (a?.count > b?.count) { return -1; }
      else { return 0; }

    })

    setContentList(newList);

  }, [data]);




  return getContent?.[type] ? getContent?.[type](contentList) : <> - </>;



}



interface IParams {
  projectHash: string;
  eventHash: string;
}

interface IProps {
  hash?: string;
}

const Resumo: React.FC<IProps> = ({ hash = '' }) => {

  const [project, setProject] = useState<Record<string, any>>({});
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const [currentReport, setCurrentReport] = useState('');

  const { projectHash = hash, eventHash } = useParams<IParams>();

  const [blockDetail, setBlockDetail] = useState<Array<Record<string, any>>>([]);
  const { token } = useAuth();
  const [report, setReport] = useState<Array<IResume>>([]);
  const [showReport, setShowReport] = useState(1);

  const load = async () => {
    setLoading(true);
    const projectReport = await api.get(!eventHash ? `/report-project/${projectHash}` : `/report-project/${projectHash}/${eventHash}`);

    setProject(projectReport.data);
    setLoading(false);
  }




  const readList = (list) => {

    const items: Array<JSX.Element> = [];


    if (list && list.length > 0) {

      const dateList: Array<Record<string, any>> = []

      list.map(l => {
        dateList.push({ date: addHours(new Date(l._id), +3), quantity: l.usuarios });

      })

      dateList.sort((a, b) => {
        if (a.date < b.date) { return 1; }
        else if (a.date > b.date) { return -1; }
        else { return 0; }

      })

      dateList.map(d => {
        const dateString = dateSimple(d.date);
        items.push(<tr className='list-template'>
          <td className='list-template-date'>{dateString}</td>
          <td className='list-template-quantity'>{d.quantity || '0'}</td>
        </tr>
        )
      })

    }

    return <table style={{ width: '100%' }}>{items}</table>;

  }

  useEffect(() => {

    load();

  }, [projectHash])

  const reportProject = async (data) => {
    if (data.date) {
      data.date = simpleDateUS(data.date);
    }
    setLoading(true);
    addToast({ title: 'Preparando download', type: 'success' })
    await downloadit({ url: !eventHash ? `${apiUrl}/report-project-list/${projectHash}?type=xlsx&date=${encodeURI(data.date ? data.date : '')}` : `${apiUrl}/report-project-list/${projectHash}/${eventHash}?type=xlsx&date=${encodeURI(data.date ? data.date : '')}` });

    setLoading(false);
  }


  const getReport = async (type) => {
    setLoading(true);
    addToast({ title: 'Preparando download', type: 'success' })
    await downloadit({ url: !eventHash ? `${apiUrl}/report-list-confirmated/${projectHash}` : `${apiUrl}/report-list-confirmated/${projectHash}/${eventHash}`, type, token });
    setLoading(false);
  }


  const getReportTellMe = async () => {
    setLoading(true);
    const response = await api.get(!eventHash ? `${apiUrl}/report-list-confirmated/${projectHash}` : `${apiUrl}/report-list-confirmated/${projectHash}/${eventHash}`);

    const data = response.data;

    const search: Array<Record<string, any>> = [];

    Object.keys(data?.searchable)?.map((key) => {
      if (!data?.searchable?.[key]?.ignore) {
        search.push(data?.searchable?.[key]);
      }
    })

    const groupParticipantes = getGroup({ prefix: 'Participantes - ', data: data.rows, search: search, where: { participou: 'Sim' } });
    const groupInscritos = getGroup({ prefix: 'Inscritos - ', data: data.rows, search: search });

    const groupParticipantesMedicos = getGroup({ prefix: 'Médicos - Participantes - ', data: data.rows, search: search, where: { participou: 'Sim', position: 'Médico' } });
    const groupInscritosMedicos = getGroup({ prefix: 'Médicos - Inscritos - ', data: data.rows, search: search, where: { position: 'Médico' } });


    setReport([...groupParticipantes, ...groupInscritos, ...groupParticipantesMedicos, ...groupInscritosMedicos]);


    setLoading(false);

  }


  const getReportTellMeBlock = async () => {
    setLoading(true);
    const response = await api.get(!eventHash ? `${apiUrl}/report-list-block/${projectHash}` : `${apiUrl}/report-list-block/${projectHash}/${eventHash}`);

    const data = response.data;

    setBlockDetail([...data]);


    setLoading(false);

  }

  const getReportTellMeProduct = async () => {
    setLoading(true);

    const products = await api.get(!eventHash ? `${apiUrl}/products-manager` : `${apiUrl}/products`,
      {
        params: {
          where: {
            project_id: projectHash,

          },
          limitItems: 10000,
          type: 'full'

        },
      });


    const response = await api.get(!eventHash ? `${apiUrl}/sales` : `${apiUrl}/sales`,
      {
        params: {
          where: {
            project_id: projectHash,

          },
          limitItems: 10000,
          type: 'full'

        }
      }
    );

    const data = response.data;
    let reports: Array<IResume> = [];
    const search: Array<Record<string, any>> = [
      { table: "Student", ref: "product_id", label: "Produto", search: true, },
      { table: "Student", ref: "company", label: "Empresa", search: true, },
      { table: "Student", ref: "category_id", label: "Especialidade", search: true },
      { table: "Student", ref: "position", label: "Categoria", search: true },
      { table: "Student", ref: "country", label: "Pais", search: true },
    ];





    products?.data?.rows?.map(product => {

      const group = getGroup({ prefix: `${product.title} - Inscritos - `, data: data.rows, search: search, where: { product_id: product._id.toString() } });
      const groupInscritosMedicos = getGroup({ prefix: `${product.title} -  Médicos - Inscritos - `, data: data.rows, search: search, where: { product_id: product._id.toString(), position: 'Médico' } });

      reports = [...reports, ...group, ...groupInscritosMedicos]

    })





    setReport(reports);


    setLoading(false);

  }



  useEffect(() => {

    if (showReport === 2) {
      getReportTellMe();
    }
    if (showReport === 3) {
      getReportTellMeBlock();
    }
    if (showReport === 4) {
      getReportTellMeProduct();
    }

  }, [showReport])


  const getUpdateReport = async () => {
    setLoading(true);

    await api.get(`${apiUrl}/update-report/${projectHash}/${eventHash}`);
    setLoading(false);
  }

  const [read, setRead] = useState('');

  return (
    <Container>
      {eventHash ? <button style={{ margin: '10px auto' }} onClick={() => getUpdateReport()} className='defaultButton'>Atualizar relatórios</button> : <></>}

      <div style={{ width: '100%', maxWidth: '350px', margin: '10px auto' }} className='defaultButton'>Gerar Relatório de Confirmados
        <FaFileExcel style={{ margin: '5px 10px', minWidth: '30px' }} onClick={() => getReport('xlsx')} size={30} />
        <FaFileCsv style={{ margin: '5px 10px', minWidth: '30px' }} onClick={() => getReport('csv')} size={30} /></div>






      {/*
      <Form onSubmit={reportProject} style={{padding: '20px', margin: '20px auto'}}>

        <DatePicker name={'date'} placeholder="Data"/>
        <button className='defaultButton'>Gerar Relatório</button>

      </Form>

  */}

      {loading ? <Loader message='Carregando... Esta solicitação pode demorar um pouco' /> :
        <>
          <h2>{project.title}</h2>

          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', width: '100%' }}>

            <button style={{ margin: '10px', padding: '10px' }} onClick={() => setShowReport(1)} className={showReport === 1 ? 'defaultButtonReverse' : 'defaultButton'} >Geral</button>
            <button style={{ margin: '10px', padding: '10px' }} onClick={() => setShowReport(2)} className={showReport === 2 ? 'defaultButtonReverse' : 'defaultButton'}>Detalhado</button>
            <button style={{ margin: '10px', padding: '10px' }} onClick={() => setShowReport(3)} className={showReport === 3 ? 'defaultButtonReverse' : 'defaultButton'}>Por bloco</button>
            <button style={{ margin: '10px', padding: '10px' }} onClick={() => setShowReport(4)} className={showReport === 4 ? 'defaultButtonReverse' : 'defaultButton'}>Por produto / inscrição</button>


          </div>
          {showReport === 2 ?
            <>

              <ReadResumeList data={report} />



            </>
            : <></>}

          {showReport === 4 ?
            <>

              <ReadResumeList data={report} />



            </>
            : <></>}
          {showReport === 1
            ? <>
              <div className='template-title-box' onClick={() => setRead(read === 'sales' ? '' : 'sales')}>
                <div style={{ width: '100%' }}>
                  <div>Inscritos no projeto:</div>
                  <p>Cadastrados na plataforma que se inscreveram para este projeto</p></div>
                <div className='list-template-quantity'> {project.sales || '-'} </div>
              </div>


              {read === 'sales' ? readList(project.sales_list || []) : <></>}

              <div className='template-title-box'>
                <div style={{ width: '100%' }}>
                  <div>Participantes totais (únicos):</div>
                  <p >Soma dos participantes ao vivo e on demand, removendo os duplicados.</p>
                </div>
                <div className='list-template-quantity'> {project.attendances || '0'}</div>
              </div>

              <div className='template-title-box'>
                <div style={{ width: '100%' }}>
                  <div>Participantes durante o evento ao vivo (únicos):</div>
                  <p >Inscritos que estiveram online durante as palestras ao vivo</p>
                </div>
                <div className='list-template-quantity'> {project.attendancesLive || '0'}</div>
              </div>

              <div className='template-title-box'>
                <div style={{ width: '100%' }}>
                  <div>Participantes on demand (únicos):</div>
                  <p>Participantes que visitaram os conteúdos após o evento ao vivo. Para efeito de cálculo, não serão consideradas visitas antes do evento ocorrer.</p>
                </div>
                <div className='list-template-quantity'> {project.attendancesOnDemand || '0'}</div>
              </div>



              {!eventHash ? <> <div onClick={() => setRead(read === 'lead' ? '' : 'lead')} className='template-title-box'>
                <div style={{ width: '100%' }}>
                  <div>Novos inscritos captados pelo projeto {eventHash ? 'evento' : 'projeto'}:</div>
                  <p>Neste cálculo, são contabilizados cadastros novos na plataforma, que não participaram de nenhum outro projeto antes.</p>
                </div>
                <div className='list-template-quantity'> {project.leads || '0'}</div>
              </div>
                {read === 'lead' ? readList(project.leads_list || []) : <></>}</>

                : <></>}



            </> : <> </>}

          {showReport === 3 ? <>

            <table style={{ width: '100%' }}>
              {blockDetail.map(d => {
                return <> <tr className='list-template'>
                  <td className='list-template-date' style={{ background: '#333', color: '#fff', width: '100%' }} colSpan={2}>{dateSimple(d.release_date)} - {d.title}</td>

                </tr>
                  <tr className='list-template'>
                    <td className='list-template-date'>Live</td>
                    <td className='list-template-quantity'>{d.live}</td>
                  </tr>
                  <tr className='list-template'>
                    <td className='list-template-date'>On Demand</td>
                    <td className='list-template-quantity'>{d.onDemand}</td>
                  </tr>
                  <tr className='list-template'>
                    <td className='list-template-date'>Total</td>
                    <td className='list-template-quantity'>{d.total}</td>
                  </tr>
                  <tr className='list-template'>
                    <td className='list-template-date'>Mensagens no chat</td>
                    <td className='list-template-quantity'>{d.chat}</td>
                  </tr>
                </>
              })}

            </table>;

          </> : <></>}</>}

    </Container>
  );
};

export default Resumo;
